<template>
  <div class="orderManagement-view">
    <el-tabs v-model="activeName"  @tab-click="handleClick">
      <el-tab-pane label="我的跟进分析" name="first">
        <mine v-if="activeName == 'first'" />
      </el-tab-pane>
      <el-tab-pane label="企业跟进分析" name="second">
        <enterprise v-if="activeName == 'second'" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import mine from "./components/followUpAnalyse/mine";
import enterprise from "./components/followUpAnalyse/enterprise";
export default {
  name: "orderManagement",
  components: {
    mine,
    enterprise,
  },
  data() {
    return {
      activeName: "first",
    };
  },
  methods: {
    handleClick(tab) {
      const { name } = tab;
      this.activeName = name;
    },
  },
};
</script>

<style scoped lang='sass'>
</style>